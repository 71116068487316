import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const HeaderStyled = styled.header([
  css`
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
  `,
  tw`
    sticky
    top-0
    w-full
    h-[80px]
    sm:h-[60px]
    md:h-[60px]
    px-container
    sm:px-mo-container
    md:px-mo-container
    flex
    items-center
    justify-center
    bg-white
    z-[99]
  `,
]);

export const InnerContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    justify-start
    gap-[30px]
    sm:gap-[10px]
    md:gap-[10px]
  `,
]);

export const NavigateItemStyled = styled.span([
  tw`
    relative
    cursor-pointer
    whitespace-nowrap
    [&>span]:text-heading3
    [&>span]:sm:text-body1
    [&>span]:md:text-body1
    [&>span]:font-bold
    [&>span]:hover:text-main
  `,
]);

export const ChildMenuContainerStyled = styled('div')(({ size }) => [
  css`
    bottom: -${size * 35 + 10}px;
  `,
  tw`
    absolute
    left-0
    w-auto
    flex
    flex-col
  `,
]);

export const ChildMenuStyled = styled.div([
  css`
    &:not(:first-of-type) {
      border-top: 1px solid white;
    }
  `,
  tw`
    w-full
    px-[30px]
    h-[35px]
    flex
    items-center
    justify-center
    bg-main
    [&>span]:text-body1
    [&>span]:text-white
    [&>span]:font-bold
    [&>span]:hover:text-gray-100
  `,
]);