import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkApplication } from 'query';
import { useModal } from 'hooks';
import { Header, DetailHeader, Footer, InputArticle, Icon } from 'components';
import {
  ConfirmButtonStyled,
  ConfirmStyled,
  ContentContainerStyled,
  InputContainerStyled, TypeBtnContainerStyled, TypeBtnWrapper,
  TypeSelectContainerStyled, TypeTextStyled, TypeTitleStyled,
} from './ConfirmStyled';

function Confirm({ ...props }) {
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const [info, setInfo] = useState({
    type: '',
    participantName: '',
    phoneNumber: '',
  });

  const handleChangeInfo = useCallback(({ name, value }) => {
    setInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleCheckClick = () => {
    checkApplication(info)
      .then(res => {
        if (res['suc']) {
          const { applicationId } = res;
          navigate(`/edit/${applicationId}`);
        } else {
          handleModal({ modalType: 'noExist' });
        }
      });
  };

  return (
    <ConfirmStyled {...props}>
      <Header />
      <DetailHeader>접수확인</DetailHeader>
      <ContentContainerStyled>
        <InputContainerStyled>
          <TypeSelectContainerStyled>
            <TypeTitleStyled>접수유형</TypeTitleStyled>
            <TypeBtnContainerStyled>
              <TypeBtnWrapper
                onClick={() => handleChangeInfo({ name: 'type', value: 'T01' })}>
                <Icon size={18}
                      name='circleCheck'
                      color={info.type === 'T01' ? '#ee7639' : '#9ca3af'}
                />
                <TypeTextStyled>청년/소상공인</TypeTextStyled>
              </TypeBtnWrapper>

              <TypeBtnWrapper
                onClick={() => handleChangeInfo({ name: 'type', value: 'T02' })}>
                <Icon size={18}
                      name='circleCheck'
                      color={info.type === 'T02' ? '#ee7639' : '#9ca3af'}
                />
                <TypeTextStyled>사업 재도약 중소기업</TypeTextStyled>
              </TypeBtnWrapper>
            </TypeBtnContainerStyled>
          </TypeSelectContainerStyled>
          <InputArticle title='신청자명'
                        inputName='participantName'
                        inputValue={info.participantName}
                        handleInputValue={handleChangeInfo}
          />
          <InputArticle title='전화번호'
                        inputType='number'
                        inputName='phoneNumber'
                        inputValue={info.phoneNumber}
                        handleInputValue={handleChangeInfo}
          />
        </InputContainerStyled>
        <ConfirmButtonStyled onClick={handleCheckClick}>접수확인</ConfirmButtonStyled>
      </ContentContainerStyled>
      <Footer />
    </ConfirmStyled>
  );
}

export default Confirm;