import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HomeStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
  `,
]);

export const MainBgContainerStyled = styled.div([
  tw`
    w-full
    aspect-[3/1]
    sm:aspect-[2/1]
    md:aspect-[2/1]
    flex
    items-center
    justify-center
    bg-white
    overflow-hidden
  `,
]);

export const MainBgImageStyled = styled.img([
  tw`
    w-full
    object-cover
  `,
]);

export const MainSectionStyled = styled.section([
  tw`
    relative
    w-full
    px-container
    sm:px-mo-container
    md:px-mo-container
    p-[80px_0_40px]
    sm:p-[40px_0_25px]
    md:p-[40px_0_25px]
    gap-[40px]
    sm:gap-[20px]
    md:gap-[20px]
    flex
    items-start
    justify-between
    sm:flex-col
    md:flex-col
    bg-white
  `,
]);

export const SignboardImageStyled = styled.img([
  css`
    left: 50%;
    transform: translate(-50%, 0);
  `,
  tw`
    absolute
    top-[-53px]
    aspect-[589/107]
    top-0
    sm:w-[250px]
    md:w-[250px]
    sm:top-[-23px]
    md:top-[-23px]
  `,
]);

export const InfoContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[20px]
    sm:gap-[10px]
    md:gap-[10px]
  `,
]);

export const InfoTitleStyled = styled.div([
  tw`
    p-[5px_10px]
    flex
    items-center
    justify-center
    rounded-[8px]
    text-heading3
    sm:text-body1
    md:text-body1
    text-white
    font-bold
    bg-main
  `,
]);

export const DescriptionContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const RowContainerStyled = styled.div([
  css`
    &:first-of-type {
      border-bottom: 1px solid #eee;
    }
  `,
  tw`
    w-full
    flex
    flex-row
    items-center
  `,
]);

export const DescTitleStyled = styled.div([
  tw`
    p-[15px_30px]
    sm:p-[5px_20px]
    md:p-[5px_20px]
    flex
    items-center
    justify-center
    bg-orange-200
    text-heading3
    sm:text-body1
    md:text-body1
    font-bold
  `,
]);

export const DescTextStyled = styled.span([
  tw`
    ml-[20px]
    text-heading3
    sm:text-body1
    md:text-body1
    font-bold
  `,
]);

export const NavigateItemContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    gap-[20px]
    sm:gap-[10px]
    md:gap-[10px]
  `,
]);

export const NavigateImageStyled = styled.img([
  tw`
    w-1/2
    aspect-square
    object-cover
  `,
]);