import React from 'react';
import { FooterStyled, FooterTextStyled } from './FooterStyled';

function Footer({ ...props }) {
  return (
    <FooterStyled {...props}>
      <FooterTextStyled>
        서울시 강남구 삼성로 104길 10, 이호빌딩 4층<br />
        Tel: 02.555.8897(내선번호 1013) | E-mail : hdining@koreabridging.com<br />
        Copyright(c)H다이닝 All Right Reserved
      </FooterTextStyled>
    </FooterStyled>
  );
}

export default Footer;