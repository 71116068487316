export default function validateInfo(info) {
  let result = true;
  for (let [key, value] of Object.entries(info)) {
    switch (key) {
      case 'website':
        break;
      case 'storesCountChange':
        if (info.type === 'T02' && Object.keys(value).filter(x => value[x].trim().length > 0).length < 2) {
          return false;
        }
        break;
      default:
        if (value.trim().length === 0) {
          return false;
        }
        break;
    }
  }
  return result;
}