import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useModal } from 'hooks';
import { Overlay } from 'components';

import Warning from './Modal/Warning';
import Confirm from './Modal/Confirm';
import NoExist from './Modal/NoExist';
import EditConfirm from './Modal/EditConfirm';

function PortalModal({ ...props }) {
  const { modal } = useModal();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!!modal) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [modal]);

  const renderModal = (modal) => {
    switch (modal.modalType) {
      case 'warning':
        return <Warning />;
      case 'confirm':
        return <Confirm />;
      case 'noExist':
        return <NoExist />;
      case 'editConfirm':
        return <EditConfirm />;
      default:
        return <></>;
    }
  };

  if (typeof window !== 'undefined' && isModalOpen) {
    return ReactDOM.createPortal(
      <React.Fragment>
        <Overlay />
        {!!modal && renderModal(modal)}
      </React.Fragment>,
      document.getElementById('portal-modal'),
    );
  } else {
    return <></>;
  }
}

export default PortalModal;