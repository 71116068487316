import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const IconStyled = styled('i')(({ size, color }) => [
  css`
    font-size: ${size}px;
    font-family: system-ui;
    width: 1em;
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    min-height: 1em;
    max-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    & * {
      width: 1em;
      height: 1em;
    }`,
  !!color && css`
    & * {
      fill: ${color}
    }
  `,
]);