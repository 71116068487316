import tw from 'twin.macro';
import styled from '@emotion/styled';


export const FooterStyled = styled.footer([
  tw`
    w-full
    sm:px-mo-container
    md:px-mo-container
    py-[30px]
    flex
    items-center
    justify-center
    bg-gray-100
  `,
]);

export const FooterTextStyled = styled.span([
  tw`
    text-body2
    sm:text-caption1
    md:text-caption1
    text-gray-400
    text-center
    font-medium
  `,
]);