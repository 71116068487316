import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SummaryStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
  `,
]);

export const SubTitleStyled = styled.div([
  css`
    font-family: nanum;
  `,
  tw`
    w-full
    py-[40px]
    sm:py-[25px]
    md:py-[25px]
    flex
    items-center
    justify-center
    text-heading1
    sm:text-heading3
    md:text-heading3
    font-medium
  `,
]);

export const ContentsContainerStyled = styled.div([
  tw`
    w-full
    px-container
    sm:px-mo-container
    md:px-mo-container
    pb-[80px]
    flex
    flex-col
    items-center
    gap-[30px]
  `,
]);

export const ListContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[20px]
  `,
]);

export const TargetDescTextStyled = styled.span([tw``]);

export const ScheduleContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    sm:flex-col
    md:flex-col
    gap-[16px]
  `,
]);

export const ScheduleItemContainerStyled = styled.div([
  tw`
    w-full
    border
    border-solid
    border-gray-400
    [&>*]:w-full
    [&>*]:py-[8px]
    [&>*]:flex
    [&>*]:items-center
    [&>*]:justify-center
    [&>*]:whitespace-nowrap
    [&>*:first-of-type]:text-gray-50
    [&>*:first-of-type]:font-bold
    [&>*:first-of-type]:bg-gray-400
  `,
]);

export const ScheduleInfoContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const ScheduleDescriptionTextStyled = styled.div([
  tw`
    w-full
    pl-[16px]
  `,
]);

export const NoticeTextStyled = styled.span([tw`text-main`]);

export const TableContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const TableRowStyled = styled.div([
  css`
    border-bottom: 1px solid #9ca3af;
  `,
  tw`
    w-full
    flex
    flex-row
    items-stretch
    [&>*]:flex
    [&>*]:flex-1
    [&>*]:p-[8px]
    [&>*]:items-center
    [&>*]:justify-center
    [&>*]:text-center
    [&>*:last-of-type]:flex-[3_1_0%]
    [&>*:last-of-type]:text-left
  `,
]);

export const TableHeaderStyled = styled(TableRowStyled)([
  css`
    border: none;
  `,
  tw`
    bg-gray-400
    [&>*]:p-[4px_8px]
    [&>*]:text-white
    [&>*]:font-bold
  `,
]);
