import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Summary, Judge, About, Agreement, Confirm, Edit } from 'page';

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
      <Route path={'/summary'} element={<Summary />} />
      <Route path={'/judge'} element={<Judge />} />
      <Route path={'/about'} element={<About />} />
      <Route path={'/agreement'} element={<Agreement />} />
      <Route path={'/confirm'} element={<Confirm />} />
      <Route path={'/edit/:id'} element={<Edit />} />
    </Routes>
  );
}

export default App;
