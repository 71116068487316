import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const JudgeStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
  `,
]);

export const ContentsContainerStyled = styled.div([
  tw`
    w-full
    px-container
    sm:px-mo-container
    md:px-mo-container
    p-[40px_80px]
    flex
    flex-col
    items-center
    gap-[30px]
  `,
]);

export const GrayContentContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[30px]
  `,
]);

export const TableContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const FirstTableHeaderStyled = styled.div([
  css`
    & > * {
      :nth-of-type(2) {
        border-left: 1px solid white;
        border-right: 1px solid white;
      }
    }
  `,
  tw`
    w-full
    flex
    items-center
    bg-gray-400
    [&>*]:text-white
    [&>*]:font-bold
    [&>*]:flex
    [&>*]:flex-1
    [&>*]:items-center
    [&>*]:justify-center
    [&>*:nth-of-type(2)]:flex-[4_1_0%]
  `,
]);

export const FirstSecondHeaderContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-center
  `,
]);

export const HalfDivisionContainerStyled = styled('div')(({ color }) => [
  css`
    border-top: 1px solid ${color ?? 'white'};

    & > * {
      :not(:first-of-type) {
        border-left: 1px solid ${color ?? 'white'};
      }
    }
  `,
  tw`
    w-full
    flex
    items-stretch
    [&>*]:w-1/2
    [&>*]:py-[4px]
    [&>*]:text-center
    [&>*]:sm:whitespace-pre-wrap
    [&>*]:md:whitespace-pre-wrap
    [&>*]:flex
    [&>*]:items-center
    [&>*]:justify-center
  `,
]);

export const FirstRowStyled = styled.div([
  css`
    border-bottom: 1px solid #d1d5db;

    & > * {
      :nth-of-type(2) {
        border-left: 1px solid #d1d5db;
        border-right: 1px solid #d1d5db;
      }
    }
  `,
  tw`
    w-full
    flex
    items-stretch
    [&>*]:flex
    [&>*]:flex-1
    [&>*]:items-center
    [&>*]:justify-center
    [&>*:nth-of-type(2)]:flex-[4_1_0%]
  `,
]);

export const MultipleItemContainerStyled = styled.div([
  css`
    & > * {
      :not(:first-of-type) {
        border-top: 1px solid #d1d5db;
      }
    }
  `,
  tw`
    w-full
    flex
    flex-col
    items-start
    [&>*]:w-full
    [&>*]:text-center
    [&>*]:py-[4px]
    [&>*]:flex
    [&>*]:items-center
    [&>*]:justify-center
  `,
]);

export const BottomContainerStyled = styled.div([
  css`
    & > * {
      :first-of-type {
        border-right: 1px solid white;
      }
    }
  `,
  tw`
    w-full
    flex
    items-center
    bg-gray-400
    [&>*]:flex
    [&>*]:flex-1
    [&>*]:py-[2px]
    [&>*]:items-center
    [&>*]:justify-center   
    [&>*]:text-white   
    [&>*]:font-bold   
    [&>*:first-of-type]:flex-[5_1_0%]
  `,
]);

export const SecondTableContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[10px]
  `,
]);

export const SecondTableTitleStyled = styled.span([tw`font-bold`]);

export const SecondTableRowStyled = styled.div([
  css`
    &:not(:first-of-type) {
      border-top: 1px solid #d1d5db;
    }

    & > * {
      :nth-of-type(2) {
        border-left: 1px solid #d1d5db;
        border-right: 1px solid #d1d5db;
      }
    }
  `,
  tw`
    w-full
    flex
    items-center
    [&>*]:flex
    [&>*]:py-[4px]
    [&>*]:justify-center
    [&>*]:text-center
    [&>*:first-of-type]:flex-[1.5_1_0%]
    [&>*:nth-of-type(2)]:px-[8px]
    [&>*:nth-of-type(2)]:flex-[3.5_1_0%]
    [&>*:last-of-type]:flex-1
  `,
]);

export const SecondTableHeaderStyled = styled(SecondTableRowStyled)([
  css`
    & > * {
      :not(:first-of-type) {
        border-left: 1px solid white;
      }
    }
  `,
  tw`
    bg-gray-400
    text-white
    font-bold
  `,
]);

export const NoticeContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
    [&>b]:text-main
  `,
]);