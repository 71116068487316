import React from 'react';
import { DetailHeader, Footer, GrayArticle, Header } from 'components';
import {
  BottomContainerStyled,
  ContentsContainerStyled,
  FirstRowStyled,
  FirstSecondHeaderContainerStyled,
  FirstTableHeaderStyled,
  GrayContentContainerStyled,
  HalfDivisionContainerStyled,
  JudgeStyled,
  MultipleItemContainerStyled,
  NoticeContainerStyled,
  SecondTableContainerStyled,
  SecondTableHeaderStyled,
  SecondTableRowStyled,
  SecondTableTitleStyled,
  TableContainerStyled,
} from './JudgeStyled';

function Judge({ ...props }) {
  return (
    <JudgeStyled {...props}>
      <Header />
      <DetailHeader>심사기준</DetailHeader>
      <ContentsContainerStyled>
        <GrayArticle title='1차 (서류) 심사 기준'>
          <GrayContentContainerStyled>
            <span>
              • 심사기간: 10월 25일(수) ~ 10월 30일(월)<br />
              • 선발인원: 총 지원자 중 4개 팀 선발 (경우에 따라, 2팀의 예비합격자 선정)<br />
              • 심사항목/배점: ‘H 다이닝’ 운영계획서 및 이후 사업계획서 중점 심사<br />
              • 심사결과 발표 일정: 10월 31일(화), 오후 5시
            </span>
            <TableContainerStyled>
              <FirstTableHeaderStyled>
                <span>구분</span>
                <span>
                  <FirstSecondHeaderContainerStyled>
                    <span style={{ padding: '4px 0' }}>세부항목</span>
                    <HalfDivisionContainerStyled>
                      <span>{`소상공인 및\n청년창업가`}</span>
                      <span>{`사업 재도약\n중소기업`}</span>
                    </HalfDivisionContainerStyled>
                  </FirstSecondHeaderContainerStyled>
                </span>
                <span>배점(점)</span>
              </FirstTableHeaderStyled>
              <FirstRowStyled>
                <span>이해도</span>
                <span style={{ padding: '4px 0' }}>지원프로그램의 이해도</span>
                <span>10</span>
              </FirstRowStyled>
              <FirstRowStyled>
                <span>적합성</span>
                <span>
                  <MultipleItemContainerStyled>
                    <span>제출 서류의 충실성 및 작성 내용의 적정성</span>
                    <HalfDivisionContainerStyled color='#d1d5db'>
                      <span>창업의지/동기 및 신청사유의 명확성</span>
                      <span>사업 재도약 계획 및 신청사유의 명확성</span>
                    </HalfDivisionContainerStyled>
                    <span>사업계획의 타당성</span>
                  </MultipleItemContainerStyled>
                </span>
                <span>20</span>
              </FirstRowStyled>
              <FirstRowStyled>
                <span>기술성</span>
                <span>
                  <MultipleItemContainerStyled>
                    <span>메뉴 구성</span>
                    <span>메뉴의 차별성 (독창성)</span>
                    <span>메뉴 적합성</span>
                    <span>지원자(팀)의 수행능력 수준 및 역량</span>
                  </MultipleItemContainerStyled>
                </span>
                <span>40</span>
              </FirstRowStyled>
              <FirstRowStyled>
                <span>성장성</span>
                <span>
                  <MultipleItemContainerStyled>
                    <span>운영지원 中, 後 성장가능성</span>
                    <span>시장진입 가능성</span>
                  </MultipleItemContainerStyled>
                </span>
                <span>20</span>
              </FirstRowStyled>
              <BottomContainerStyled>
                <span>총계</span>
                <span>100</span>
              </BottomContainerStyled>
            </TableContainerStyled>
          </GrayContentContainerStyled>
        </GrayArticle>
        <GrayArticle title='2차 (면접/실기) 심사 기준'>
          <GrayContentContainerStyled>
            <span>
              • 심사기간 : 11월 1일(수) ~ 11월 2일(목)<br />
              • 선발인원: 1차 합격 총 4팀 중 1팀 선발 (경우에 따라, 1팀의 예비합격자 선정)<br />
              • 심사항목/배점: 면접 (50%), 실기 (50%)<br />
              • 심사결과 발표 일정: 11월 10일(금), 오후 5시
            </span>
            <SecondTableContainerStyled>
              <SecondTableTitleStyled>1. 면접</SecondTableTitleStyled>
              <TableContainerStyled>
                <SecondTableHeaderStyled>
                  <span>항목</span>
                  <span>평가지표</span>
                  <span>배점(점)</span>
                </SecondTableHeaderStyled>
                <SecondTableRowStyled>
                  <span>태도 및 책임감</span>
                  <span>평가 대상이 사업을 진행하기 적절한 인성과 책임감을 가지고 있는가?</span>
                  <span>20</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>사업 지속가능성</span>
                  <span>평가 대상이 6개월 간 문제없이 사업을 영위할 수 있으며, 지원기간 이후에도 원활히 독립할 수 있는가?</span>
                  <span>20</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>사업 전문성</span>
                  <span>외식사업 분야의 지식을 가지고 있으며, 적절한 사업능력을 가지고 있는가?</span>
                  <span>30</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>아이템 경쟁력</span>
                  <span>해당 아이템이 시장에 부합하는 또는 독창적인 경쟁력을 보유하고 있는가?</span>
                  <span>30</span>
                </SecondTableRowStyled>
                <BottomContainerStyled>
                  <span>총계</span>
                  <span>100</span>
                </BottomContainerStyled>
              </TableContainerStyled>
            </SecondTableContainerStyled>
            <SecondTableContainerStyled>
              <SecondTableTitleStyled>2. 실기</SecondTableTitleStyled>
              <TableContainerStyled>
                <SecondTableHeaderStyled>
                  <span>항목</span>
                  <span>평가지표</span>
                  <span>배점(점)</span>
                </SecondTableHeaderStyled>
                <SecondTableRowStyled>
                  <span>조리능력</span>
                  <span>평가 대상이 동선, 조리기구, 장비 등을 효율적으로 관리하는가?</span>
                  <span>20</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>음식 평가</span>
                  <span>외식사업 분야의 지식을 가지고 있으며, 적절한 사업능력을 가지고 있는가?</span>
                  <span>30</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>메뉴 적합도</span>
                  <span>메뉴의 맛(매운 맛 등의 정도)가 해당 시장에 부합하는가?</span>
                  <span>30</span>
                </SecondTableRowStyled>
                <SecondTableRowStyled>
                  <span>위생 및 청결서비스</span>
                  <span>
                    조리과정이 위생적인가?<br />
                    플레이팅이 서비스에 적절한가?
                  </span>
                  <span>20</span>
                </SecondTableRowStyled>
                <BottomContainerStyled>
                  <span>총계</span>
                  <span>100</span>
                </BottomContainerStyled>
              </TableContainerStyled>
            </SecondTableContainerStyled>

            <NoticeContainerStyled>
              <b>*2차 심사 유의사항</b>
              <span>
                시연메뉴는 매장에서 만패 가능한 메뉴로 제조원가 비율 및 매장운영의 현실성을 고려하여 준비하여햐 함.<br />
                시연에 사용되는 메뉴에 대한 설명은 필수 항목이며, 시연하는 동안 심사위원에게 충분히 설명할 수 있어야 함.<br />
                총 시연시간은 20분을 기준으로 함<br />
                당일 지참품: 메뉴에 사용되는 특수 식재료 및 조리도구<br />
                심사장 구비물품 : 주방 설비류 및 기본 조리도구, 사전 공수가능한 기본적인 식재료*식재료는 사전 요청하여 공수가능한 식재료에 한함 (원산지, 수량 등의 보장은 불가능)
              </span>
            </NoticeContainerStyled>
          </GrayContentContainerStyled>
        </GrayArticle>
      </ContentsContainerStyled>
      <Footer />
    </JudgeStyled>
  );
}

export default Judge;