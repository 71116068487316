export default function checkApplication({ participantName, phoneNumber, type }) {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_API_SERVER}/application/checkApplication?participantName=${participantName}&phoneNumber=${phoneNumber}&type=${type}`)
        .then(res => res.json())
        .then(json => {
          resolve(json);
        });
    } catch (e) {
      reject(e);
    }
  });
}