import React from 'react';
import { NavigateButtonStyled, TextStyled } from './NavigateButtonStyled';

function NavigateButton({ title, children, ...props }) {
  return (
    <NavigateButtonStyled {...props}>
      {children}
      <TextStyled>{title}</TextStyled>
    </NavigateButtonStyled>
  );
}

export default NavigateButton;