export default function refinedInfo(info) {
  let result = {};
  for (let [key, value] of Object.entries(info)) {
    switch (key) {
      case 'website':
        if (value.trim().length > 0) {
          result[key] = value;
        }
        break;
      case 'storesCountChange':
        for (let [_k, _v] of Object.entries(value)) {
          if (info['type'] === 'T02') {
            if (_v.trim().length > 0) {
              result[_k] = _v;
            }
          } else {
            result[_k] = null;
          }
        }
        break;
      default:
        result[key] = value;
        break;
    }
  }

  return result;
}