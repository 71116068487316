export default function insertApplicationForm(application) {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_API_SERVER}/application/insertApplicationForm`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ application }),
        })
        .then(res => res.json())
        .then(json => {
          resolve(json);
        });
    } catch (e) {
      reject(e);
    }
  });
}