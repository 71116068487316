import React from 'react';
import { DetailHeader, Footer, GrayArticle, Header } from 'components';
import {
  ContentsContainerStyled,
  ListContainerStyled,
  NoticeTextStyled,
  ScheduleContainerStyled,
  ScheduleDescriptionTextStyled,
  ScheduleInfoContainerStyled,
  ScheduleItemContainerStyled,
  SubTitleStyled,
  SummaryStyled,
  TableContainerStyled,
  TableHeaderStyled,
  TableRowStyled,
  TargetDescTextStyled,
} from './SummaryStyled';

function Summary({ ...props }) {
  return (
    <SummaryStyled {...props}>
      <Header />
      <DetailHeader>공모개요</DetailHeader>
      <SubTitleStyled>H 다이닝 3기</SubTitleStyled>
      <ContentsContainerStyled>
        <GrayArticle title='목적'>
          음식점 창업을 희망하는 청년 또는 소상공인이 호반프로퍼티 상생협력기금의 지원을 받아 'H다이닝'을 운영하고, 운영기간 동안의 수익금 및 운영종료 후 지급하는 창업지원상금으로 자립의 기반을
          마련함으로써 상생 발전을 위한 사회적 가치를 구현함
        </GrayArticle>
        <GrayArticle title='주최'>
          대중소기업 농어업 협력재단
        </GrayArticle>
        <GrayArticle title='주관'>
          브릿징파트너스
        </GrayArticle>
        <GrayArticle title='후원'>
          호반프라퍼티
        </GrayArticle>
        <GrayArticle title='참가대상'>
          <ListContainerStyled>
            <TargetDescTextStyled>
              <b>1. 음식점 창업을 희망하는 예비 창업 청•</b><br />
              - 참가 형태 : 1~4인 구성 (팀의 경우 대표자 자격 충족 필수)<br />
              - 청년 : 공고일 기준, 만 25세 이상 ~ 만 39세 이하<br />
              - 예비창업자 : 모집공고일 기준 외식업 또는 유사 업종 개인사업자 또는 법인 등록을 아니한 자로서, 본 사업 참여자 선정시 사업자등록이 가능한 자<br />
              - 음식점 운영을 위한 기본 소양을 갖추고, ‘H 다이닝’ 매장을 운영하며 외식 창업을 준비하고자 하는 자
            </TargetDescTextStyled>
            <TargetDescTextStyled>
              <b>2. 외식업 또는 유사업종을 영위하고 있는 상시근로자 5인 미만의 소상공인(중소기업)</b><br />
              - 참가 형태 : 1~4인 구성(팀의 경우 대표자 자격 충족 필수)<br />
              - 소상공인 : 공고일 기준, 외식업 또는 식품산업을 영위하는 상시근로자 5인 미만의 개인사업자<br />
              - 모집공고일 기준 가맹사업을 전개하고 있지 아니하며, ‘H 다이닝’ 에서 기영업장의 체인점 형태의 운영 또는 기영업장과 다른 외식업을 전개하고자 하는 자
            </TargetDescTextStyled>
            <TargetDescTextStyled>
              <b>3. 사업재도약 중소기업</b><br />
              - 참가 형태 : 1~4인 구성(팀의 경우 대표자 자격 충족 필수)<br />
              - 사업재도약 기업 : 공고일 기준 10개 이하 매장을 보유한 기업 중 최근 3년간 매장 수가 20% 이상 감소한 기업<br />
              - 모집공고일 기준 가맹사업을 전개하고 있으며, ‘H 다이닝’ 에서 기영업장의 직영점 형태의 운영을 하고자 하는 자
            </TargetDescTextStyled>
          </ListContainerStyled>
        </GrayArticle>
        <GrayArticle title='선정혜택' notice='자세한 내용은 ‘운영방법’ 참고'>
          ∙ 사업장 제공 (운영기간 내 별도 임대료 없음)<br />
          ∙ 매장 인테리어 조성 및 주방기기/기물 등 제반설비 지원<br />
          ∙ 운영 전후 전반에 관한 인큐베이팅 교육(컨설팅)<br />
          ∙ 음식점 운영에 필요한 사업자등록 등 절차 자문<br />
          ∙ ‘H 다이닝’ 우수평가업체는 1,000만원의 창업자금지원 <b>(오픈 후 6개월 간 평가를 통해 우수평가업체 선정)</b>
        </GrayArticle>
        <GrayArticle title='제외대상'>
          ∙ 신청서, 증명서, 사업계획서 등 본 사업 관련 서류를 허위로 기재한 자<br />
          ∙ 타 중앙정부, 자치단체, 공공기관 등 타 사업에서 지원을 받고 있거나 지원기간이 중복되는 자<br />
          ∙ 채무 불이행자로 규제중인 자(금융기관), 세금 체납자
        </GrayArticle>
        <GrayArticle title='필수 제출서류'>
          <ListContainerStyled>
            <TableContainerStyled>
              <TableHeaderStyled>
                <span>서류명</span>
                <span>대상자</span>
                <span>제출처</span>
                <span>작성방법</span>
              </TableHeaderStyled>
              <TableRowStyled>
                <span>지원신청서</span>
                <span>
                  • 창업청년<br />
                  • 소상공인<br />
                  • 사업 재도약 기업
                </span>
                <span>접수 시 자동제출</span>
                <span>접수신청 양식에 맞추어 내용기입</span>
              </TableRowStyled>
              <TableRowStyled>
                <span>자가진단표</span>
                <span>
                  • 창업청년<br />
                  • 소상공인<br />
                  • 사업 재도약 기업
                </span>
                <span>접수 시 자동제출</span>
                <span>접수신청 양식에 해당 여부 체크</span>
              </TableRowStyled>
              <TableRowStyled>
                <span>사업자등록증</span>
                <span>
                  • 소상공인<br />
                  • 사업 재도약 기업
                </span>
                <span>이메일 별도 제출</span>
                <span>
                  • 개인사업자 : 사업자등록증명원 또는 사업자등록즉 사본<br />
                  • 법인사업자 : 법인등기부등본 사본
                </span>
              </TableRowStyled>
              <TableRowStyled>
                <span>국세•지방세 완납증명서</span>
                <span>
                  • 소상공인<br />
                  • 사업 재도약 기업
                </span>
                <span>이메일 별도 제출</span>
                <span>국세청 홈텍스 발급</span>
              </TableRowStyled>
              <TableRowStyled>
                <span>중소기업 확인서</span>
                <span>
                  • 소상공인<br />
                  • 사업 재도약 기업
                </span>
                <span>이메일 별도 제출</span>
                <span>중소벤처24 발급</span>
              </TableRowStyled>
            </TableContainerStyled>
            <NoticeTextStyled>* 별도 제출서류는 hdining@koreabridging.com 으로 제출 요망</NoticeTextStyled>
          </ListContainerStyled>
        </GrayArticle>
        <GrayArticle title='선택 제출서류'>
          <ListContainerStyled>
            <TableContainerStyled>
              <TableHeaderStyled>
                <span>서류명</span>
                <span>대상자</span>
                <span>제출처</span>
                <span>작성방법</span>
              </TableHeaderStyled>
              <TableRowStyled>
                <span>대학/기관 추천서</span>
                <span>전원</span>
                <span>이메일 별도 제출</span>
                <span>
                  • 지역단위 지원사업 선정 증명서 등<br />
                  • 지도교수 추천서 등<br />
                  *대학/기관 날인 공식문서만 인정
                </span>
              </TableRowStyled>
              <TableRowStyled>
                <span>자격증명서</span>
                <span>전원</span>
                <span>이메일 별도 제출</span>
                <span>국가에서 인정하는 조리 관련 자격증명서</span>
              </TableRowStyled>
              <TableRowStyled>
                <span>기술/제품 우수성 증빙자료</span>
                <span>전원</span>
                <span>이메일 별도 제출</span>
                <span>
                  • 조리 관련 기술의 특허증 및 인증서<br />
                  • 제품의 특허증 및 인증서
                </span>
              </TableRowStyled>
            </TableContainerStyled>
            <NoticeTextStyled>* 선택 제출서류는 hdining@koreabridging.com 으로 제출 요망</NoticeTextStyled>
          </ListContainerStyled>
        </GrayArticle>
        <GrayArticle title='선발 일정'>
          <ScheduleContainerStyled>
            <ScheduleItemContainerStyled>
              <div>서류접수</div>
              <div>09.18 ~ 10.24</div>
            </ScheduleItemContainerStyled>
            <ScheduleItemContainerStyled>
              <div>1차 심사</div>
              <div>10.25 ~ 10.31</div>
            </ScheduleItemContainerStyled>
            <ScheduleItemContainerStyled>
              <div>2차 심사</div>
              <div>11.01 ~ 11.08</div>
            </ScheduleItemContainerStyled>
            <ScheduleItemContainerStyled>
              <div>선정자 발표</div>
              <div>11.10</div>
            </ScheduleItemContainerStyled>
            <ScheduleItemContainerStyled>
              <div>개점준비</div>
              <div>11.13 ~ 12.13</div>
            </ScheduleItemContainerStyled>
          </ScheduleContainerStyled>
        </GrayArticle>
        <GrayArticle title='일정설명' notice='상기 일정은 당사 사정에 따라 변동될 수 있음'>
          <ListContainerStyled>
            <ScheduleInfoContainerStyled>
              ◦ 공모전 서류 접수
              <ScheduleDescriptionTextStyled>
                기간 : 9월 18일 ~ 10월 24일<br />
                지원방법: 웹사이트를 통한 접수<br />
                마감일 제출 시, 서류보완은 요청에 따라 2일 내 진행하며, 기한을 초과하는 경우 심사에 영향을 줄 수 있음.
              </ScheduleDescriptionTextStyled>
            </ScheduleInfoContainerStyled>
            <ScheduleInfoContainerStyled>
              ◦ 1차 심사 및 결과 발표
              <ScheduleDescriptionTextStyled>
                기간: 10월 25일 ~ 10월 31일<br />
                심사결과 발표 일정: 10월 31일, 오후 5시<br />
                확인방법: 개별 이메일, 문자 통보<br />
                결과 발표일에는 심사결과에 상관없이 제출서류는 반환되지 않음.
              </ScheduleDescriptionTextStyled>
            </ScheduleInfoContainerStyled>
            <ScheduleInfoContainerStyled>
              ◦ 2차 심사 (면접/실기)
              <ScheduleDescriptionTextStyled>
                기간: 11월 01일 ~ 11월 02일<br />
                면접/실기 일정: 1차 심사 합격자에 한해 개별통보
              </ScheduleDescriptionTextStyled>
            </ScheduleInfoContainerStyled>
            <ScheduleInfoContainerStyled>
              ◦ 최종 선정자 발표
              <ScheduleDescriptionTextStyled>
                심사결과 발표 일정: 11월 10일, 오후 5시<br />
                확인방법: 개별 이메일, 문자 통보<br />
                결과 발표일에는 심사결과에 상관없이 제출서류는 반환되지 않음.
              </ScheduleDescriptionTextStyled>
            </ScheduleInfoContainerStyled>
            <ScheduleInfoContainerStyled>
              ◦ 개점 준비 및 사전 멘토링
              <ScheduleDescriptionTextStyled>
                기간: 11월 13일 ~ 12월 13일<br />
                개점 일정: 12월 13일
              </ScheduleDescriptionTextStyled>
            </ScheduleInfoContainerStyled>
            <NoticeTextStyled>* 상기 일정은 당사 사정에 따라 변동될 수 있습니다.</NoticeTextStyled>
          </ListContainerStyled>
        </GrayArticle>

      </ContentsContainerStyled>
      <Footer />
    </SummaryStyled>
  );
}

export default Summary;