import React from 'react';
import { IconStyled } from './IconStyled';

import IconChevronRight from './Icons/IconChevronRight';
import IconCircleCheck from './Icons/IconCircleCheck';
import IconClose from './Icons/IconClose';

function Icon({ size, name, color, ...props }) {
  const generateIcon = (name) => {
    switch (name) {
      case 'chevronRight':
        return <IconChevronRight />;
      case 'circleCheck':
        return <IconCircleCheck />;
      case 'close':
        return <IconClose />;
      default:
        break;
    }
  };
  return (
    <IconStyled size={size} color={color} {...props}>
      {generateIcon(name)}
    </IconStyled>
  );
}

export default Icon;