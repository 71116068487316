import { useCallback, useMemo, useId, useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { modalState } from 'stores/modal';

export default function useModal() {
  const uniqueId = useId();
  const [_modal, setModal] = useRecoilState(modalState);
  const clearModal = useResetRecoilState(modalState);

  const handleModal = useCallback((modalInfo) => {
    console.log(modalInfo);
    setModal({ ...modalInfo, modalKey: uniqueId });
  }, [_modal]);

  const modal = useMemo(() => _modal, [_modal]);

  return { modal, handleModal, clearModal };
}