import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SelfDiagnosisArticleStyled = styled.article([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const SelfDiagnosisTitleStyled = styled.h2([
  css`
    font-family: nanum;
  `,
  tw`
    m-0
    p-0
    text-body1
    text-gray-500
    font-bold
  `,
]);