import React from 'react';
import {
  MaxLengthTextStyled,
  NoticeTextStyled,
  TextareaArticleStyled, TextareaStyled,
  TitleContainerStyled,
  TitleTextStyled,
} from './TextareaArticleStyled';

function TextareaArticle({
                           title,
                           notice,
                           textareaName,
                           textareaValue,
                           handleTextareaValue,
                           ...props
                         }) {
  return (
    <TextareaArticleStyled {...props}>
      <TitleContainerStyled>
        <TitleTextStyled>{title}<b>*</b></TitleTextStyled>
        {!!notice && <NoticeTextStyled>{notice}</NoticeTextStyled>}
      </TitleContainerStyled>
      <TextareaStyled name={textareaName}
                      value={textareaValue || ''}
                      maxLength={500}
                      onChange={(e) => {
                        if (!!handleTextareaValue) {
                          handleTextareaValue(e.target);
                        }
                      }}
      />
      <MaxLengthTextStyled>{textareaValue.length}/500</MaxLengthTextStyled>
    </TextareaArticleStyled>
  );

}

export default TextareaArticle;