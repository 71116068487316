import tw from 'twin.macro';
import styled from '@emotion/styled';


export const AboutStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
  `,
]);

export const ContentsContainerStyled = styled.div([
  tw`
    w-full
    px-container
    sm:px-mo-container
    md:px-mo-container
    my-[40px]
    flex
    flex-col
    items-center
    gap-[30px]
  `,
]);

export const ListContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    gap-[20px]
  `,
]);

export const InnerContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const InnerContentContainerStyled = styled.div([
  tw`
    w-full
    pl-[16px]
  `,
]);

export const MapImageStyled = styled.img([
  tw`
    w-full
    object-cover
  `,
]);