import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'hooks';
import { EditConfirmStyled, TextStyled, ConfirmButtonStyled } from './EditConfirmStyled';

function EditConfirm({ ...props }) {
  const navigate = useNavigate();
  const { clearModal } = useModal();
  return (
    <EditConfirmStyled {...props}>
      <TextStyled>수정이 정상적으로 완료되었습니다.</TextStyled>
      <ConfirmButtonStyled
        onClick={() => {
          navigate('/');
          clearModal();
        }}>
        홈으로
      </ConfirmButtonStyled>
    </EditConfirmStyled>
  );
}

export default EditConfirm;
