import React from 'react';
import { DetailHeaderStyled, TitleTextStyled } from './DetailHeaderStyled';

function DetailHeader({ children, ...props }) {
  return (
    <DetailHeaderStyled {...props}>
      <TitleTextStyled>{children}</TitleTextStyled>
    </DetailHeaderStyled>
  );
}

export default DetailHeader;