import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SponsorStyled = styled.div([
  css`
    border-top: 1px solid #eeeeee;
  `,
  tw`
    w-full
    px-container
    py-[30px]
    flex
    flex-row
    items-center
    justify-center
    bg-white
    sm:px-mo-container
    md:px-mo-container
  `,
]);

export const InnerContainerStyled = styled.div([
  tw`
    w-full
    gap-[100px]
    flex
    items-center
    justify-center
    sm:gap-[30px]
    md:gap-[30px]
    sm:flex-col
    md:flex-col
    sm:items-start
    md:items-start
  `,
]);

export const SponsorItemStyled = styled.div([
  tw`
    w-[250px]
    sm:w-full
    md:w-full
    flex
    flex-row
    gap-[20px]
    items-center
    sm:justify-center
    md:justify-center
    [&>span]:text-body2
    [&>span]:font-bold
    [&>span]:whitespace-nowrap
  `],
);

export const SponsorImageStyled = styled.img([
  tw`
    w-full
    sm:w-1/3
    md:w-1/3
    h-auto
    object-cover
  `,
]);
