import styled from '@emotion/styled';
import tw from 'twin.macro';

export const OverlayStyled = styled.div([
  tw`
    fixed
    top-0
    left-0
    bottom-0
    right-0
    bg-black/[0.3]
    z-[999]
  `,
]);