import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const NavigateButtonStyled = styled.button([
  css`
    all: unset;
  `,
  tw`
    w-full
    flex
    flex-col
    aspect-square
    items-center
    justify-center
    gap-[8px]
    sm:gap-[4px]
    md:gap-[4px]
    border-[3px]
    border-solid
    border-main
    rounded-[12px]
    cursor-pointer
    transition-colors
    duration-300
    hover:bg-orange-50
  `,
]);

export const TextStyled = styled.span([
  tw`
    text-heading4
    sm:text-body1
    md:text-body1
    text-main
    font-bold
  `,
]);