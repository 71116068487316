import React from 'react';
import {
  SponsorStyled,
  InnerContainerStyled,
  SponsorItemStyled,
  SponsorImageStyled,
} from './SponsorStyled';

function Sponsor({ ...props }) {
  return (
    <SponsorStyled {...props}>
      <InnerContainerStyled>
        <SponsorItemStyled>
          <span>주최</span>
          <SponsorImageStyled
            alt='win-win-logo'
            src={require('assets/logo/logo_win-win.png')}
          />
        </SponsorItemStyled>
        <SponsorItemStyled>
          <span>주관</span>
          <SponsorImageStyled
            alt='bridging-logo'
            src={require('assets/logo/logo_bridging.png')}
          />
        </SponsorItemStyled>
        <SponsorItemStyled>
          <span>후원</span>
          <SponsorImageStyled
            alt='win-win-logo'
            src={require('assets/logo/logo_hoban.png')}
          />
        </SponsorItemStyled>
      </InnerContainerStyled>
    </SponsorStyled>
  );
}

export default Sponsor;