import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PortalModalStyled } from '../../PortalModalStyled';

export const EditConfirmStyled = styled(PortalModalStyled)([
  tw`
    w-1/3
    sm:w-[calc(100vw-40px)]
    md:w-[calc(100vw-40px)]
    py-[20px]
    px-[40px]
    sm:px-[20px]
    md:px-[20px]
    flex
    flex-col
    items-center
    gap-[30px]
    sm:gap-[15px]
    md:gap-[15px]
    bg-white
    rounded-[8px]
  `,
]);

export const TextStyled = styled.span([
  tw`
    text-heading3
    sm:text-body1
    md:text-body1
    text-center
    font-bold
  `,
]);

export const ConfirmButtonStyled = styled.button([
  css`
    all: unset;
  `,
  tw`
    w-full
    py-[6px]
    sm:py-[4px]
    md:py-[4px]
    flex
    items-center
    justify-center
    text-body1
    sm:text-body2
    md:text-body2
    text-white
    font-bold
    rounded-[8px]
    cursor-pointer
    bg-main
  `,
]);