import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer, Header, Sponsor, NavigateButton } from 'components';
import {
  DescriptionContainerStyled,
  DescTextStyled,
  DescTitleStyled,
  HomeStyled,
  InfoContainerStyled,
  InfoTitleStyled,
  MainBgContainerStyled,
  MainBgImageStyled,
  MainSectionStyled, NavigateImageStyled,
  NavigateItemContainerStyled,
  RowContainerStyled,
  SignboardImageStyled,
} from './HomeStyled';

function Home({ ...props }) {
  const navigate = useNavigate();
  return (
    <HomeStyled {...props}>
      <Header />
      <MainBgContainerStyled>
        <MainBgImageStyled
          alt='home_bg'
          src={require('assets/images/bg_main.png')} />
      </MainBgContainerStyled>
      <MainSectionStyled>
        <SignboardImageStyled src={require('assets/images/singboard.png')} />
        <InfoContainerStyled>
          <InfoTitleStyled>접수기간</InfoTitleStyled>
          <DescriptionContainerStyled>
            <RowContainerStyled>
              <DescTitleStyled>사전 접수</DescTitleStyled>
              <DescTextStyled>09월 18일 ~ 10월 01일</DescTextStyled>
            </RowContainerStyled>
            <RowContainerStyled>
              <DescTitleStyled>공식 접수</DescTitleStyled>
              <DescTextStyled>10월 02일 ~ 10월 24일</DescTextStyled>
            </RowContainerStyled>
          </DescriptionContainerStyled>
        </InfoContainerStyled>
        <NavigateItemContainerStyled>
          <NavigateButton title='공모개요'
                          onClick={() => navigate('/summary')}>
            <NavigateImageStyled
              alt='icon_summary'
              src={require('assets/images/icon_summary.png')}
            />
          </NavigateButton>
          <NavigateButton title='접수하기'
                          onClick={() => navigate('/agreement?type=T01')}>
            <NavigateImageStyled
              alt='icon_summary'
              src={require('assets/images/icon_agreement.png')}
            />
          </NavigateButton>
          <NavigateButton title='접수확인'
                          onClick={() => navigate('/confirm')}>
            <NavigateImageStyled
              alt='icon_summary'
              src={require('assets/images/icon_confirm.png')}
            />
          </NavigateButton>
        </NavigateItemContainerStyled>
      </MainSectionStyled>
      <Sponsor />
      <Footer />
    </HomeStyled>
  );
}

export default Home;