import React from 'react';

export default function IconChevronRight({ ...props }) {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='navigation/chevron_right_24px'>
        <rect x='0.5' y='24' width='24' height='24' rx='12' transform='rotate(-90 0.5 24)' fill='#ECECEC' />
        <g id='arrow'>
          <path id='Rectangle 401'
                d='M9.85336 17.6464C10.0486 17.8417 10.3652 17.8417 10.5605 17.6464L15.5102 12.6967C15.7055 12.5014 15.7055 12.1849 15.5102 11.9896C15.3149 11.7943 14.9984 11.7943 14.8031 11.9896L9.85336 16.9393C9.65809 17.1346 9.65809 17.4512 9.85336 17.6464Z'
                fill='black' />
          <path id='Rectangle 402'
                d='M15.5101 12.6963C15.7054 12.5011 15.7054 12.1845 15.5101 11.9892L10.5603 7.03947C10.3651 6.84421 10.0485 6.84421 9.85324 7.03947C9.65798 7.23474 9.65798 7.55132 9.85324 7.74658L14.803 12.6963C14.9982 12.8916 15.3148 12.8916 15.5101 12.6963Z'
                fill='black' />
        </g>
      </g>
    </svg>
  );
}