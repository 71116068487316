import React from 'react';
import { SelfDiagnosisArticleStyled, SelfDiagnosisTitleStyled } from './SelfDiagnosisArticleStyled';

function SelfDiagnosisArticle({ title, children, ...props }) {
  return (
    <SelfDiagnosisArticleStyled {...props}>
      <SelfDiagnosisTitleStyled>{title}</SelfDiagnosisTitleStyled>
      {children}
    </SelfDiagnosisArticleStyled>
  );
}

export default SelfDiagnosisArticle;