export default function updateApplicationInfo(id, application) {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_API_SERVER}/application/updateApplicationInfo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, application }),
      })
        .then(res => res.json())
        .then(json => {
          resolve(json);
        });
    } catch (e) {
      reject(e);
    }
  });
}