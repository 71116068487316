import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ArticleTitleStyled = styled.h2([
  css`
    font-family: nanum`,
  tw`
    m-0
    p-0
    text-heading2
    sm:text-heading3
    md:text-heading3
    font-bold
  `,
]);