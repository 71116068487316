import React, { useEffect } from 'react';
import { OverlayStyled } from './OverlayStyled';

function Overlay({ children, ...props }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return <OverlayStyled {...props}>{children}</OverlayStyled>;
}

export default Overlay;