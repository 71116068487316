import React from 'react';
import { DetailHeader, Footer, GrayArticle, Header } from 'components';
import {
  AboutStyled,
  ContentsContainerStyled,
  InnerContainerStyled,
  ListContainerStyled,
  InnerContentContainerStyled,
  MapImageStyled,
} from './AboutStyled';

function About({ ...props }) {
  return (
    <AboutStyled {...props}>
      <Header />
      <DetailHeader>운영방법 및 지원사항</DetailHeader>
      <ContentsContainerStyled>
        <GrayArticle title='운영방법'>
          <ListContainerStyled>
            <InnerContainerStyled>
              ∙ 운영관련 참고사항
              <InnerContentContainerStyled>
                - 매장위치: 아브뉴프랑 광명점 1층 B2-78호, 전용 16평 (舊 로봇김밥)<br />
                - 운영자: ‘H 다이닝 3기 ’ 운영자 공모를 통하여 최종선발된 자<br />
                - 운영기간: 약 6개월 (2023. 12. 13 ~ 2024. 6. 12)
              </InnerContentContainerStyled>
            </InnerContainerStyled>
            <InnerContainerStyled>
              ∙ 매장 위치 정보
              <InnerContentContainerStyled>
                <MapImageStyled alt='map' src={require('assets/images/map.png')} />
              </InnerContentContainerStyled>
            </InnerContainerStyled>
          </ListContainerStyled>
        </GrayArticle>
        <GrayArticle title='지원사항'>
          ∙ 외식사업 수행에 필요한 사업장 제공 (운영기간 내 별도 임대료 없음)<br />
          ∙ 매장 인테리어 조성 및 주방기기 등 제반설비 지원 (소모성 기물, 비품은 제외)<br />
          ∙ 초기 주방 기물 구입비용 지원<br />
          ∙ 운영 전후, 조리, 접객, 식재료 조달, 위생, 외식경영 등 전반에 관한 인큐베이팅 교육(컨설팅)<br />
          ∙ 음식점 운영에 필요한 사업자등록 등 절차 자문<br />
          ∙ ‘H 다이닝’ 우수평가업체는 1,000만원의 창업자금지원
        </GrayArticle>
      </ContentsContainerStyled>
      <Footer />
    </AboutStyled>
  );
}

export default About;