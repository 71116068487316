import React from 'react';
import {
  InputArticleStyled,
  InputStyled,
  TitleContainerStyled,
  TitleTextStyled,
  NoticeTextStyled,
} from './InputArticleStyled';

function InputArticle({
                        isRequired,
                        title,
                        notice,
                        inputType,
                        inputName,
                        inputValue,
                        handleInputValue,
                        ...props
                      }) {
  return (
    <InputArticleStyled {...props}>
      <TitleContainerStyled>
        <TitleTextStyled>{title}{isRequired && <b>*</b>}</TitleTextStyled>
        {!!notice && <NoticeTextStyled>{notice}</NoticeTextStyled>}
      </TitleContainerStyled>
      <InputStyled
        name={inputName}
        value={inputValue || ''}
        type={inputType ?? 'text'}
        onChange={(e) => {
          if (!!handleInputValue) {
            handleInputValue(e.target);
          }
        }} />
    </InputArticleStyled>
  );
}

export default InputArticle;