import React from 'react';

export default function IconClose({ ...props }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.9996 13.8496L7.32461 18.5246C7.07461 18.7746 6.76628 18.8996 6.39961 18.8996C6.03294 18.8996 5.72461 18.7746 5.47461 18.5246C5.22461 18.2746 5.09961 17.9663 5.09961 17.5996C5.09961 17.2329 5.22461 16.9246 5.47461 16.6746L10.1496 11.9996L5.47461 7.32461C5.22461 7.07461 5.09961 6.76628 5.09961 6.39961C5.09961 6.03294 5.22461 5.72461 5.47461 5.47461C5.72461 5.22461 6.03294 5.09961 6.39961 5.09961C6.76628 5.09961 7.07461 5.22461 7.32461 5.47461L11.9996 10.1496L16.6746 5.47461C16.9246 5.22461 17.2329 5.09961 17.5996 5.09961C17.9663 5.09961 18.2746 5.22461 18.5246 5.47461C18.7746 5.72461 18.8996 6.03294 18.8996 6.39961C18.8996 6.76628 18.7746 7.07461 18.5246 7.32461L13.8496 11.9996L18.5246 16.6746C18.7746 16.9246 18.8996 17.2329 18.8996 17.5996C18.8996 17.9663 18.7746 18.2746 18.5246 18.5246C18.2746 18.7746 17.9663 18.8996 17.5996 18.8996C17.2329 18.8996 16.9246 18.7746 16.6746 18.5246L11.9996 13.8496Z'
        fill='#171A1C' />
    </svg>

  );
}