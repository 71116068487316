import React from 'react';
import { useModal } from 'hooks';
import { ConfirmButtonStyled, TextStyled, WarningStyled } from './WarningStyled';

function Warning({ ...props }) {
  const { clearModal } = useModal();
  return (
    <WarningStyled {...props}>
      <TextStyled>필수 질문(별표)에 답해주시기 바랍니다.</TextStyled>
      <ConfirmButtonStyled onClick={clearModal}>확인</ConfirmButtonStyled>
    </WarningStyled>
  );
}

export default Warning;