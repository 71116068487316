import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const DetailHeaderStyled = styled.div([
  tw`
    w-full
    px-container
    sm:px-mo-container
    md:px-mo-container
    flex
    items-center
    justify-center
  `,
]);

export const TitleTextStyled = styled.h1([
  css`
    font-family: nanum;
    border-bottom: 1px solid #eee;
  `,
  tw`
    m-0
    p-0
    w-full
    py-[60px]
    sm:py-[30px]
    md:py-[30px]
    flex
    items-center
    justify-center
    text-heading1
    sm:text-heading3
    md:text-heading3
    font-bold
  `,
]);