export default function getApplicationInfoById(id) {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_API_SERVER}/application/getApplicationInfoById?id=${id}`)
        .then(res => res.json())
        .then(json => {
          resolve(json);
        });
    } catch (e) {
      reject(e);
    }
  });
}