import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GrayArticleStyled = styled.article([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const TitleContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    gap-[8px]
    sm:flex-col
    md:flex-col
    sm:gap-[4px]
    md:gap-[4px]
    sm:items-start
    md:items-start
  `,
]);

export const NoticeTextStyled = styled.span([
  css`
    font-family: nanum`,
  tw`
    text-body1
    sm:text-body2
    md:text-body2
    text-main
    font-medium
  `,
]);

export const GrayContainerStyled = styled.div([
  tw`
    w-full
    p-[20px]
    rounded-[8px]
    bg-gray-100
    text-body1
    sm:text-caption1
    md:text-caption1
    text-gray-600
    [&>b]:text-main
    [&>b]:font-medium
  `,
]);