import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PortalModalStyled = styled.div([
  css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  tw`
    p-[28px]
    z-[999]
    overflow-y-auto
    bg-white
  `,
]);