import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AgreementStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const ContentsContainerStyled = styled.div([
  tw`
    w-full
    py-[40px]
    px-container
    sm:px-mo-container
    md:px-mo-container
    flex
    flex-col
    items-start
    gap-[40px]
  `,
]);

export const InnerContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[20px]
  `,
]);

export const SubtitleContainerStyled = styled.div([
  css`
    font-family: nanum;
  `,
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const SubTitleStyled = styled.p([
  css`
    font-family: nanum;
  `,
  tw`
    m-0
    p-0
    text-heading3
    sm:text-body1
    md:text-body1
    font-bold
  `,
]);

export const NoticeTextStyled = styled.span([
  tw`
    text-body1
    sm:text-caption1
    md:text-caption1
    text-main
  `,
]);

export const ListContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[16px]
  `,
]);

export const SelfDiagnosisTableContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const SelfDiagnosisRowStyled = styled.div([
  css`
    border-bottom: 1px solid #9ca3af;

    & > * {
      :not(:first-of-type) {
        border-left: 1px solid #9ca3af;
      }
    }
  `,
  tw`
    w-full
    flex
    items-stretch
    text-body1
    sm:text-caption1
    md:text-caption1
    [&>*]:flex
    [&>*]:py-[4px]
    [&>*]:flex-1
    [&>*]:items-center
    [&>*]:justify-center
    [&>*:nth-of-type(2)]:flex-[6_1_0%]
    [&>*:nth-of-type(2)]:px-[8px]
    [&>*:nth-of-type(3)]:cursor-pointer
    [&>*:nth-of-type(4)]:cursor-pointer
  `,
]);

export const SelfDiagnosisHeaderStyled = styled(SelfDiagnosisRowStyled)([
  css`
    & > * {
      :not(:first-of-type) {
        border-left: 1px solid white;
      }
    }
  `,
  tw`
    whitespace-nowrap
    bg-gray-400
    font-bold
    text-white
  `,
]);

export const ConfirmContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-center
    gap-[40px]
  `,
]);

export const AgreementContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-center
    gap-[10px]
  `,
]);

export const AgreementNoticeTextStyled = styled.span([
  tw`
    text-body1
    text-center
    sm:text-caption1
    md:text-caption1
    text-gray-500
  `,
]);

export const AgreementButtonStyled = styled.button([
  css`
    all: unset;
  `,
  tw`
    p-[5px_40px]
    rounded-[8px]
    flex
    items-center
    justify-center
    text-heading3
    text-white
    font-bold
    bg-main
    cursor-pointer
  `,
]);

export const TypeContainerStyled = styled.div([
  css`
    & > p {
      all: unset;
      font-family: nanum;
    }
  `,
  tw`
    flex
    flex-row
    items-center
    gap-[8px]
    [&>p]:text-heading3
    [&>p]:sm:text-body1
    [&>p]:md:text-body1
    [&>p]:text-gray-700
    [&>p]:font-bold
    cursor-pointer
  `,
]);

export const ChangeStoresArticleStyled = styled.article([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const StoreTitleContainerStyled = styled.div([
  css`
    font-family: nanum;
  `,
  tw`
    w-full
    flex
    flex-row
    sm:flex-col
    md:flex-col
    items-center
    sm:items-start
    md:items-start
    gap-[8px]
    sm:gap-[4px]
    md:gap-[4px]
  `,
]);

export const StoreTitleTextStyled = styled.span([
  tw`
    text-body1
    text-gray-500
    font-bold
    [&>b]:text-main
  `,
]);

export const StoreNoticeTextStyled = styled.span([
  tw`
    text-body2
    text-gray-400
    font-bold
  `,
]);

export const StoreInputListStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[12px]
  `,
]);

export const StoreInputContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    gap-[12px]
  `,
]);

export const StoreInputTitleStyled = styled.span([
  css`
    font-family: nanum;
  `,
  tw`
    text-body2
    text-gray-500
    font-bold
    whitespace-nowrap
  `,
]);

export const StoreInputStyled = styled.input([
  css`
    all: unset;
    border-bottom: 1px solid #9ca3af;
    font-family: 'Noto Sans KR', sans-serif;
  `,
  tw`
    w-full
    p-[4px]
    text-body1
  `,
]);

