import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'hooks';
import { getApplicationInfo, updateApplicationInfo } from 'query';
import { refinedInfo, validateInfo } from 'utils';
import { DetailHeader, Footer, Header, Icon, InputArticle, SelfDiagnosisArticle, TextareaArticle } from 'components';
import {
  SelfDiagnosisHeaderStyled,
  SelfDiagnosisRowStyled,
  SelfDiagnosisTableContainerStyled,
  StoreInputContainerStyled,
  StoreInputListStyled,
  StoreInputStyled,
  StoreInputTitleStyled,
  StoreNoticeTextStyled,
  StoreTitleContainerStyled,
  StoreTitleTextStyled,
} from '../Agreement/AgreementStyled';
import {
  EditStyled,
  ContentsContainerStyled,
  InnerContainerStyled,
  SubtitleContainerStyled,
  SubTitleStyled,
  NoticeTextStyled,
  ListContainerStyled,
  TypeContainerStyled,
  ChangeStoresArticleStyled,
  ConfirmContainerStyled,
  AgreementContainerStyled,
  AgreementNoticeTextStyled,
  AgreementButtonStyled,
} from './EditStyled';


function Edit({ ...props }) {
  const params = useParams();
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const [info, setInfo] = useState(null);
  const [type, setType] = useState('');
  const [registrationInfo, setRegistrationInfo] = useState({
    brandName: '',
    participantName: '',
    phoneNumber: '',
    email: '',
    website: '',
  });
  const [projectInfo, setProjectInfo] = useState({
    projectName: '',
    menuName: '',
    menuDivision: '',
    numberOfTeams: '',
  });
  const [storesCountChange, setStoresCountChange] = useState({
    store2019: '',
    store2020: '',
    store2021: '',
    store2022: '',
    store2023: '',
  });
  const [applicationInfo, setApplicationInfo] = useState({
    motive: '',
    compatibility: '',
    plan: '',
  });
  const [selfDiagnosis, setSelfDiagnosis] = useState({
    selfDiagnosis01: '',
    selfDiagnosis02: '',
    selfDiagnosis03: '',
    selfDiagnosis04: '',
    selfDiagnosis05: '',
  });

  useEffect(() => {
    const { id } = params;
    if (!!id) {
      getApplicationInfo(id)
        .then(res => {
          if (res['suc']) {
            const { application } = res;
            setInfo(application);
          } else {
            navigate(-1);
          }
        });
    } else {
      navigate(-1);
    }
  }, [params]);

  useEffect(() => {
    if (!!info) {
      for (let [key, value] of Object.entries(info)) {
        switch (key) {
          case 'type':
            setType(value);
            break;
          case 'brandName':
          case 'participantName':
          case 'phoneNumber':
          case 'email':
          case 'website':
            if (!!value) {
              handleRegistrationInfo({ name: key, value });
            }
            break;
          case 'projectName':
          case 'menuName':
          case 'menuDivision':
          case 'numberOfTeams':
            handleProjectInfo({ name: key, value });
            break;
          case 'store2019':
          case 'store2020':
          case 'store2021':
          case 'store2022':
          case 'store2023':
            if (!!value) {
              handleStoresCount({ name: key, value });
            }
            break;
          case 'motive':
          case 'compatibility':
          case 'plan':
            handleApplicationInfo({ name: key, value });
            break;
          case 'selfDiagnosis01':
          case 'selfDiagnosis02':
          case 'selfDiagnosis03':
          case 'selfDiagnosis04':
          case 'selfDiagnosis05':
            handleSelfDiagnosis(key, value);
            break;
          default:
            break;
        }
      }
    }
  }, [info]);

  const handleRegistrationInfo = useCallback(({ name, value }) => {
    setRegistrationInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleProjectInfo = useCallback(({ name, value }) => {
    setProjectInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleStoresCount = useCallback(({ name, value }) => {
    setStoresCountChange(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleApplicationInfo = useCallback(({ name, value }) => {
    setApplicationInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSelfDiagnosis = useCallback((name, value) => {
    setSelfDiagnosis(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = () => {
    const combinedInfo = {
      type,
      storesCountChange,
      ...registrationInfo,
      ...projectInfo,
      ...applicationInfo,
      ...selfDiagnosis,
    };

    if (validateInfo(combinedInfo)) {
      const refined = refinedInfo(combinedInfo);
      updateApplicationInfo(info.id, refined)
        .then(res => {
          if (res['suc']) {
            handleModal({ modalType: 'editConfirm' });
          }
        });
    } else {
      handleModal({ modalType: 'warning' });
    }
  };

  return (
    <EditStyled {...props}>
      <Header />
      <DetailHeader>접수확인</DetailHeader>
      <ContentsContainerStyled>
        <InnerContainerStyled>
          <SubTitleStyled>접수타입</SubTitleStyled>
          <ListContainerStyled>
            <TypeContainerStyled onClick={() => setType('T01')}>
              <Icon size={18}
                    color={type === 'T01' ? '#ee7639' : '#9ca3af'}
                    name='circleCheck' />
              <p>청년/소상공인</p>
            </TypeContainerStyled>
            <TypeContainerStyled
              onClick={() => setType('T02')}>
              <Icon size={18}
                    color={type === 'T02' ? '#ee7639' : '#9ca3af'}
                    name='circleCheck' />
              <p>사업 재도약 중소기업</p>
            </TypeContainerStyled>
          </ListContainerStyled>
        </InnerContainerStyled>

        <InnerContainerStyled>
          <SubtitleContainerStyled>
            <ListContainerStyled>
              <InputArticle isRequired
                            title='법인명/브랜드명'
                            inputName='brandName'
                            inputValue={registrationInfo.brandName}
                            handleInputValue={handleRegistrationInfo}
              />
              <InputArticle isRequired
                            title='신청자명'
                            inputName='participantName'
                            inputValue={registrationInfo.participantName}
                            handleInputValue={handleRegistrationInfo}
              />
              <InputArticle isRequired
                            title='전화번호'
                            notice='(-) 를 제외한 숫자만'
                            inputType='number'
                            inputName='phoneNumber'
                            inputValue={registrationInfo.phoneNumber}
                            handleInputValue={handleRegistrationInfo}
              />
              <InputArticle isRequired
                            title='이메일'
                            inputName='email'
                            inputValue={registrationInfo.email}
                            handleInputValue={handleRegistrationInfo}
              />
              <InputArticle title='웹사이트/SNS'
                            notice='(보유시 기입)'
                            inputName='website'
                            inputValue={registrationInfo.website}
                            handleInputValue={handleRegistrationInfo}
              />
            </ListContainerStyled>
          </SubtitleContainerStyled>
        </InnerContainerStyled>

        <InnerContainerStyled>
          <SubtitleContainerStyled>
            <SubTitleStyled>프로젝트</SubTitleStyled>
          </SubtitleContainerStyled>
          <ListContainerStyled>
            <InputArticle isRequired
                          title='프로젝트(식당)명'
                          inputName='projectName'
                          inputValue={projectInfo.projectName}
                          handleInputValue={handleProjectInfo}
            />
            <InputArticle isRequired
                          title='주력메뉴 이름'
                          inputName='menuName'
                          inputValue={projectInfo.menuName}
                          handleInputValue={handleProjectInfo}
            />
            <InputArticle isRequired
                          title='메뉴 구분'
                          notice='(한식, 양식, 일식, 중식 등)'
                          inputName='menuDivision'
                          inputValue={projectInfo.menuDivision}
                          handleInputValue={handleProjectInfo}
            />
            <InputArticle isRequired
                          title='팀원(직원)수'
                          inputType='number'
                          inputName='numberOfTeams'
                          inputValue={projectInfo.numberOfTeams}
                          handleInputValue={handleProjectInfo}
            />
          </ListContainerStyled>
        </InnerContainerStyled>

        {
          type === 'T02' &&
          <ChangeStoresArticleStyled>
            <StoreTitleContainerStyled>
              <StoreTitleTextStyled>매장 수 변화<b>*</b></StoreTitleTextStyled>
              <StoreNoticeTextStyled>2019년부터 현재까지의 매장 수 변화 기록 (최소 2개 이상)</StoreNoticeTextStyled>
            </StoreTitleContainerStyled>
            <StoreInputListStyled>
              <StoreInputContainerStyled>
                <StoreInputTitleStyled>2019년</StoreInputTitleStyled>
                <StoreInputStyled name='store2019'
                                  value={storesCountChange.store2019}
                                  onChange={(e) => handleStoresCount(e.target)}
                />
              </StoreInputContainerStyled>
              <StoreInputContainerStyled>
                <StoreInputTitleStyled>2020년</StoreInputTitleStyled>
                <StoreInputStyled name='store2020'
                                  value={storesCountChange.store2020}
                                  onChange={(e) => handleStoresCount(e.target)}
                />
              </StoreInputContainerStyled>
              <StoreInputContainerStyled>
                <StoreInputTitleStyled>2021년</StoreInputTitleStyled>
                <StoreInputStyled name='store2021'
                                  value={storesCountChange.store2021}
                                  onChange={(e) => handleStoresCount(e.target)}
                />
              </StoreInputContainerStyled>
              <StoreInputContainerStyled>
                <StoreInputTitleStyled>2022년</StoreInputTitleStyled>
                <StoreInputStyled name='store2022'
                                  value={storesCountChange.store2022}
                                  onChange={(e) => handleStoresCount(e.target)}
                />
              </StoreInputContainerStyled>
              <StoreInputContainerStyled>
                <StoreInputTitleStyled>2023년</StoreInputTitleStyled>
                <StoreInputStyled name='store2023'
                                  value={storesCountChange.store2023}
                                  onChange={(e) => handleStoresCount(e.target)}
                />
              </StoreInputContainerStyled>
            </StoreInputListStyled>
          </ChangeStoresArticleStyled>
        }

        <InnerContainerStyled>
          <SubtitleContainerStyled>
            <SubTitleStyled>신청서 (각 항목 500자 내외 서술)</SubTitleStyled>
            <NoticeTextStyled>*신청서 작성 중간에 제출하기를 통해 중간 저장이 가능합니다.</NoticeTextStyled>
          </SubtitleContainerStyled>
          <ListContainerStyled>
            <TextareaArticle title='1. 왜 ‘H 다이닝’에 지원했는가?'
                             notice='(지원동기, 목표, 자세 등)'
                             textareaName='motive'
                             textareaValue={applicationInfo.motive}
                             handleTextareaValue={handleApplicationInfo}
            />
            <TextareaArticle title='2. 나는 ‘H 다이닝’에 적합한 인물인가?'
                             notice={type === 'T01' ? '(외식업 경험, 요리역량 등)' : '(외식업 경험, 사업 재도약 계획 등)'}
                             textareaName='compatibility'
                             textareaValue={applicationInfo.compatibility}
                             handleTextareaValue={handleApplicationInfo}
            />
            <TextareaArticle title='3. 입점 후 구체적인 사업계획을 서술하시오.'
                             notice='(메뉴설정, 타겟층, 원가분석, 인력관리, 객단가 등)'
                             textareaName='plan'
                             textareaValue={applicationInfo.plan}
                             handleTextareaValue={handleApplicationInfo}
            />
            <SelfDiagnosisArticle title='4. 자가진단표'>
              <SelfDiagnosisTableContainerStyled>
                <SelfDiagnosisHeaderStyled>
                  <span>연번</span>
                  <span>질문</span>
                  <span>예</span>
                  <span>아니오</span>
                </SelfDiagnosisHeaderStyled>
                <SelfDiagnosisRowStyled>
                  <span>1</span>
                  <span>{type === 'T01' ? '귀하는 만 20세~만 39세에 해당됩니까?' : '공고일 기준, 가맹사업을 전개하고 있지 않은 상태입니까?'}</span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis01', 'Y');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis01 === 'Y' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis01', 'N');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis01 === 'N' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                </SelfDiagnosisRowStyled>
                <SelfDiagnosisRowStyled>
                  <span>2</span>
                  <span>{type === 'T01' ? '공고일 기준, 가맹사업을 전개하고 있지 않은 상태입니까?' : '공고일 기준, 10개 이하의 매장을 보유하고 있습니까?'}</span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis02', 'Y');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis02 === 'Y' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis02', 'N');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis02 === 'N' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                </SelfDiagnosisRowStyled>
                <SelfDiagnosisRowStyled>
                  <span>3</span>
                  <span>{type === 'T01' ? '공고일 기준, 사업장에 상시근로자가 5인 미만에 해당됩니까? (사업장이 없을 경우, 아니오)' : '최근 3년 내 매장수가 20%이상 감소했습니까?'}</span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis03', 'Y');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis03 === 'Y' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis03', 'N');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis03 === 'N' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                </SelfDiagnosisRowStyled>
                <SelfDiagnosisRowStyled>
                  <span>4</span>
                  <span>공고일 기준 정부/단체/기관 등 타 사업에서 지원을 받지 않고 있습니까?</span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis04', 'Y');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis04 === 'Y' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis04', 'N');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis04 === 'N' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                </SelfDiagnosisRowStyled>
                <SelfDiagnosisRowStyled>
                  <span>5</span>
                  <span>채무불이행, 탈세 등으로 사업장 운영에 제한이 없는 상태입니까?</span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis05', 'Y');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis05 === 'Y' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                  <span onClick={() => {
                    handleSelfDiagnosis('selfDiagnosis05', 'N');
                  }}>
                    <Icon size={18}
                          color={selfDiagnosis.selfDiagnosis05 === 'N' ? '#ee7639' : '#d1d5db'}
                          name='circleCheck'
                    />
                  </span>
                </SelfDiagnosisRowStyled>
              </SelfDiagnosisTableContainerStyled>
            </SelfDiagnosisArticle>
          </ListContainerStyled>
        </InnerContainerStyled>

        <ConfirmContainerStyled>
          <AgreementContainerStyled>
            <AgreementNoticeTextStyled>
              상기 유의사항을 확인했으며, 해당 자가진단표 내용과 위배되는 사항이 발견될 경우, 해당 지원사업에서의 불이익에 대한 책임은 지원자(팀)에게 있습니다.
            </AgreementNoticeTextStyled>
          </AgreementContainerStyled>
          <AgreementButtonStyled onClick={handleSubmit}>수정하기</AgreementButtonStyled>
        </ConfirmContainerStyled>
      </ContentsContainerStyled>
      <Footer />
    </EditStyled>
  );
}

export default Edit;