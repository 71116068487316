import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChildMenuContainerStyled,
  ChildMenuStyled,
  HeaderStyled,
  InnerContainerStyled,
  NavigateItemStyled,
} from './HeaderStyled';

function Header({ ...props }) {
  const navigate = useNavigate();
  const [activeChild, setActiveChild] = useState('');
  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <HeaderStyled {...props}>
      <InnerContainerStyled>
        {
          [
            {
              path: '/',
              headerName: 'HOME',
              hasChild: false,
              child: null,
            },
            {
              path: null,
              headerName: '공모정보',
              hasChild: true,
              child: [
                { path: '/summary', headerName: '공모개요' },
                { path: '/judge', headerName: '심사기준' },
              ],
            },
            {
              path: '/about',
              headerName: 'H다이닝',
              hasChild: false,
              child: null,
            },
            {
              path: null,
              headerName: '접수하기',
              hasChild: true,
              child: [
                { path: '/agreement?type=T01', headerName: '접수신청' },
                { path: '/confirm', headerName: '접수확인' },
              ],
            },
          ].map((x, idx) => {
            return (
              <NavigateItemStyled
                key={idx}
                onClick={() => {
                  if (x.hasChild) {
                    setActiveChild(prevState => {
                      if (prevState === x.headerName) {
                        return '';
                      } else {
                        return x.headerName;
                      }
                    });
                  } else {
                    handleClick(x.path);
                  }
                }}
              >
                <span>{x.headerName}</span>
                {
                  activeChild === x.headerName &&
                  <ChildMenuContainerStyled size={x.child.length}>
                    {
                      x.child.map((c, cIdx) => {
                        return (
                          <ChildMenuStyled key={cIdx}
                                           onClick={() => handleClick(c.path)}>
                            <span>{c.headerName}</span>
                          </ChildMenuStyled>
                        );
                      })
                    }
                  </ChildMenuContainerStyled>
                }
              </NavigateItemStyled>
            );
          })
        }
      </InnerContainerStyled>
    </HeaderStyled>
  );
}

export default Header;