import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'hooks';
import {
  ConfirmStyled,
  ButtonContainerStyled,
  ConfirmButtonStyled,
  TextStyled,
  TextContainerStyled,
} from './ConfirmStyled';

function Confirm({ ...props }) {
  const navigate = useNavigate();
  const { clearModal } = useModal();
  return (
    <ConfirmStyled {...props}>
      <TextContainerStyled>
        <TextStyled>H다이닝 3기 지원에 감사드립니다.</TextStyled>
        <TextStyled>{`추가 및 선택 제출서류는 아래 이메일 주소로 제출해주세요.\nhdining@koreabridging.com`}</TextStyled>
        <TextStyled> 공고 마감일 이전일까지 접수확인을 통해 수정이 가능합니다.</TextStyled>
      </TextContainerStyled>

      <ButtonContainerStyled>
        <ConfirmButtonStyled
          onClick={() => {
            navigate('/');
            clearModal();
          }}>
          홈으로
        </ConfirmButtonStyled>
        <ConfirmButtonStyled
          onClick={() => {
            navigate('/confirm');
            clearModal();
          }}>
          접수확인
        </ConfirmButtonStyled>
      </ButtonContainerStyled>
    </ConfirmStyled>
  );
}

export default Confirm;