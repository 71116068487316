import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TextareaArticleStyled = styled.article([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const TitleContainerStyled = styled.div([
  css`
    font-family: nanum;
  `,
  tw`
    w-full
    flex
    flex-row
    sm:flex-col
    md:flex-col
    sm:items-start
    md:items-start
    items-center
    gap-[8px]
    sm:gap-[4px]
    md:gap-[4px]
  `,
]);

export const TitleTextStyled = styled.span([
  tw`
    text-body1
    text-gray-500
    font-bold
    [&>b]:text-main
  `,
]);

export const NoticeTextStyled = styled.span([
  tw`
    text-body2
    sm:text-caption1
    md:text-caption1
    text-main
    font-bold
  `,
]);

export const TextareaStyled = styled.textarea([
  css`
    font-family: 'Noto Sans KR', sans-serif;
  `,
  tw`
    w-full
    min-h-[150px]
    p-[8px]
    resize-y
    text-body1
    rounded-[8px]
    border
    border-solid
    border-gray-400
  `,
]);

export const MaxLengthTextStyled = styled.div([
  tw`
    w-full
    flex
    justify-end
    text-body2
    text-gray-400
    font-bold
  `,
]);