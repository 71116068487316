import React from 'react';
import { useModal } from 'hooks';
import { NoExistStyled, TextStyled, ConfirmButtonStyled } from './NoExistStyled';

function NoExist({ ...props }) {
  const { clearModal } = useModal();

  return (
    <NoExistStyled {...props}>
      <TextStyled>{`요청하신 내용이 존재하지 않습니다,\n다시 한번 확인해주세요`}</TextStyled>
      <ConfirmButtonStyled onClick={clearModal}>확인</ConfirmButtonStyled>
    </NoExistStyled>
  );
}

export default NoExist;