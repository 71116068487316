import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputArticleStyled = styled.article([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const TitleContainerStyled = styled.div([
  css`
    font-family: nanum;
  `,
  tw`
    w-full
    flex
    flex-row
    sm:flex-col
    md:flex-col
    items-center
    sm:items-start
    md:items-start
    gap-[8px]
    sm:gap-[4px]
    md:gap-[4px]
  `,
]);

export const TitleTextStyled = styled.span([
  tw`
    text-body1
    text-gray-500
    font-bold
    [&>b]:text-main
  `,
]);

export const NoticeTextStyled = styled.span([
  tw`
    text-body2
    text-gray-400
    font-bold
  `,
]);

export const InputStyled = styled.input([
  css`
    all: unset;
    border-bottom: 1px solid #9ca3af;
    font-family: 'Noto Sans KR', sans-serif;
  `,
  tw`
    w-full
    p-[8px]
    text-body1
  `,
]);