import tw from 'twin.macro';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ConfirmStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
  `,
]);

export const ContentContainerStyled = styled.div([
  tw`
    w-full
    py-[40px]
    px-container
    sm:px-mo-container
    md:px-mo-container
    min-h-[calc(100vh-200px)]
    flex
    flex-col
    items-center
    gap-[40px]
    bg-white
  `,
]);

export const InputContainerStyled = styled.div([
  tw`
    w-1/2
    sm:w-full
    md:w-full
    flex
    flex-col
    items-center
    gap-[30px]
  `,
]);

export const ConfirmButtonStyled = styled.button([
  css`
    all: unset;
  `,
  tw`
    p-[5px_40px]
    rounded-[8px]
    flex
    items-center
    justify-center
    text-heading3
    text-white
    font-bold
    bg-main
    cursor-pointer
  `,
]);

export const TypeSelectContainerStyled = styled.div([
  tw`
    w-full
    flex
    flex-col
    items-start
    gap-[8px]
  `,
]);

export const TypeTitleStyled = styled.span([
  css`
    font-family: nanum;
  `,
  tw`
    text-body1
    text-gray-500
    font-bold
  `,
]);

export const TypeBtnContainerStyled = styled.div([
  tw`
    w-full
    flex
    items-center
    justify-start
    gap-[20px]
  `,
]);

export const TypeBtnWrapper = styled.div([
  tw`
    flex
    flex-row
    items-center
    gap-[8px]
    cursor-pointer
  `,
]);

export const TypeTextStyled = styled.span([
  css`
    font-family: nanum;
  `,
  tw`
    font-bold
    text-gray-700
    text-heading3
    sm:text-body1
    md:text-body1
  `,
]);