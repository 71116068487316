import React from 'react';
import { ArticleTitle } from 'components';
import {
  GrayArticleStyled,
  GrayContainerStyled,
  NoticeTextStyled,
  TitleContainerStyled,
} from './GrayArticleStyled';

function GrayArticle({ title, notice, children, ...props }) {
  return (
    <GrayArticleStyled {...props}>
      <TitleContainerStyled>
        <ArticleTitle>{title}</ArticleTitle>
        {!!notice && <NoticeTextStyled>*{notice}</NoticeTextStyled>}
      </TitleContainerStyled>
      <GrayContainerStyled>{children}</GrayContainerStyled>
    </GrayArticleStyled>
  );
}

export default GrayArticle;